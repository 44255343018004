import { faArrowLeft, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Modal from "react-modal";
import Select from "react-select";
import { ToastContainer } from "react-toastr";
import styled from "styled-components";
import * as yup from "yup";

import CustomButtonSpinner from "components/buttonSpinner";
import ContainerFluid from "components/containers/container-fluid";
import FormField from "components/FormField";
import { cpfMask, numberMask, phoneMask } from "mask";
import Api from "services/api";
import { COLORS } from "services/constants.js";
import New_api from "services/new-api";
import { ValidationsHelper } from "services/validations";

import "./styles.css";

Modal.setAppElement("#root");

ModalBank.propTypes = {
  toggleFunction: PropTypes.shape({
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
  }),
  callBack: PropTypes.func,
  bankEdit: PropTypes.shape({
    index: PropTypes.number,
    holder_name: PropTypes.string,
    holder_document: PropTypes.string,
    transfer_bank_id: PropTypes.string,
    account_type: PropTypes.string,
    agency: PropTypes.string,
    account: PropTypes.string,
    account_digit: PropTypes.string,
    pix_key: PropTypes.string,
    pix_value: PropTypes.string,
    activeModal: PropTypes.bool,
    id: PropTypes.number,
  }),
  organizationName: PropTypes.string,
};

const RANDOM_CHARACTER_LENGTH = 32;

const RANDOM_CHARACTER__MAX_LENGTH = 36;

const RANDOM_ALPHANUMERIC_REGEX = /^[a-zA-Z0-9-]+$/;


const pixValueValidation = (pixType, schema, t) => {
  switch (pixType) {
    case "phone":
      return schema
        .required(
          t("configuration.bankAccount.validation.required.pixKeyType.phone")
        )
        .matches(
          /^\((\d{2})\) \D*(\d{5}|\d{4})\D*(\d{4})$/,
          t("configuration.bankAccount.validation.valid.pixKeyType.phone")
        );
    case "email":
      return schema
        .email(t("configuration.bankAccount.validation.valid.pixKeyType.email"))
        .required(
          t("configuration.bankAccount.validation.required.pixKeyType.email")
        );
    case "document":
      return schema
        .required(
          t("configuration.bankAccount.validation.required.pixKeyType.document")
        )
        .test(
          "documentTest",
          t("configuration.bankAccount.validation.valid.document"),
          (value) =>
            value &&
            (ValidationsHelper.isCpfValid(value) ||
              ValidationsHelper.isCnpjValid(value))
        );
    case "random":
      return schema
        .required(
          t("configuration.bankAccount.validation.required.pixKeyType.random")
        )
        .test(
          "randomLengthTest",
          t(
            "configuration.bankAccount.validation.valid.pixKeyType.randomLength"
          ),
          (value) =>
            value &&
            value.length >= RANDOM_CHARACTER_LENGTH &&
            value.length <= RANDOM_CHARACTER__MAX_LENGTH
        )
        .test(
          "randomAlphanumericTest",
          t(
            "configuration.bankAccount.validation.valid.pixKeyType.randomAlphanumeric"
          ),
          (value) => RANDOM_ALPHANUMERIC_REGEX.test(value)
        );
    default:
      return schema.required(
        t("configuration.bankAccount.validation.required.pixKeyType.key")
      );
  }
};

const createFormSchema = (t) =>
  yup.object({
    holder_name: yup
      .string()
      .trim()
      .required(t("configuration.bankAccount.validation.required.holderName")),
    holder_document: yup
      .string()
      .trim()
      .test(
        "documentTest",
        t("configuration.bankAccount.validation.valid.document"),
        (value) =>
          value &&
          (ValidationsHelper.isCpfValid(value) ||
            ValidationsHelper.isCnpjValid(value))
      )
      .required(
        t("configuration.bankAccount.validation.required.holderDocument")
      ),
    transfer_bank_id: yup
      .number()
      .integer()
      .required(
        t("configuration.bankAccount.validation.required.transferBank")
      ),
    account_type: yup
      .string()
      .trim()
      .required(t("configuration.bankAccount.validation.required.accountType")),
    agency: yup
      .string()
      .trim()
      .required(t("configuration.bankAccount.validation.required.agency")),
    account: yup
      .string()
      .trim()
      .required(t("configuration.bankAccount.validation.required.account")),
    account_digit: yup
      .string()
      .trim()
      .required(t("configuration.bankAccount.validation.required.digit")),
    pix_key: yup
      .string()
      .trim()
      .when("checked", {
        is: true,
        then: (schema) =>
          schema.required(
            t("configuration.bankAccount.validation.required.pixKey")
          ),
        otherwise: (schema) => schema.nullable(),
      }),
    pix_value: yup
      .string()
      .trim()
      .when("checked", {
        is: true,
        then: (schema) =>
          schema.when("pix_key", (value, schema) => {
            return pixValueValidation(value, schema, t);
          }),
        otherwise: (schema) => schema.nullable(),
      }),
    checked: yup.boolean(),
  });

let container;

export default function ModalBank(props) {
  const {
    toggleFunction: { isOpen, setIsOpen },
    callBack,
    bankEdit: {
      index,
      holder_name,
      holder_document,
      transfer_bank_id,
      account_type,
      agency,
      account,
      account_digit,
      pix_key,
      pix_value,
      activeModal,
      id,
    },
    orgId,
    organizationName,
    contractNumber,
  } = props;

  const [checked, setChecked] = useState(false);
  const [banks, setBanks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user") || null);
  const [hasContractNumber, setHasContractNumber] = useState(true);
  const close = () => {
    if (isLoading) {
      return;
    } else {
      setIsOpen(false);
      reset();
    }
  };

  const { t } = useTranslation("organizations");

  const formSchema = createFormSchema(t);

  const {
    reset,
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: "all",
    resolver: yupResolver(formSchema),
  });

  const selectBank = (e) => {
    setValue("transfer_bank_id", e.value, { shouldDirty: true });
  };

  const _fetchBanks = () => {
    Api.get("lista/bancos")
      .then((res) => {
        const data = res.data.map((item) => ({
          value: item.id,
          name: item.code + " - " + item.name,
        }));

        setBanks(data);
      })
      .catch(() => setBanks([]));
  };

  const keyType = watch("pix_key");

  const onSubmit = (form) => {
    if (orgId) {
      setIsLoading(true);
      const body = {
        ...form,
        organization_id: orgId,
        pix_key: form.pix_key || null,
        pix_value: form.pix_value || null,
      };

      const apiCall = id
        ? New_api.put(`/bank_accounts/${id}`, body)
        : New_api.post(`/bank_accounts`, body);

      apiCall
        .then((res) => {
          callBack(res.data, index);
          close();
          reset();
          container.success(
            res.config.method === "post"
              ? t("configuration.bankAccount.toast.success.add")
              : t("configuration.bankAccount.toast.success.update")
          );
        })
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
    } else {
      callBack(form, index);
      close();
      reset();
    }
  };
  useEffect(()=>{
    if (contractNumber > 0) {
      setHasContractNumber(true);
    } else {
      setHasContractNumber(false);
    }
  }, [contractNumber]);

  useEffect(() => {
    if (isOpen && holder_name) {
      setValue("holder_name", holder_name);
      setValue("holder_document", holder_document);
      setValue("transfer_bank_id", transfer_bank_id);
      setValue("account_type", account_type);
      setValue("agency", agency);
      setValue("account", account);
      setValue("account_digit", account_digit);
      setValue("pix_key", pix_key || null);
      setValue("checked", !!pix_value);
      setValue("pix_value", pix_value || null);
      setValue("activeModal", activeModal);
      setChecked(!!pix_value);
    }
    // eslint-disable-next-line
  }, [isOpen, holder_name]);

  const hasAccountBankPermission = () => {
    return !!(!hasContractNumber && (user?.is_admin || !orgId));
  };

  useEffect(() => {
    setValue("checked", checked);
    if (!checked) {
      setValue("pix_value", null);
      setValue("pix_key", null);
    }
    // eslint-disable-next-line
  }, [checked]);

  useEffect(() => {
    _fetchBanks();
    // eslint-disable-next-line
  }, []);

  const handleResetPixValue = (event) => {
    if (event?.target?.value) {
      setValue("pix_key", event.target.value, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
    setValue("pix_value", "");
  };

  const handleHasPix = (event) => {
    setChecked(event.target.checked);
    if (!event.target.checked) {
      setValue("pix_key", "");
      handleResetPixValue();
    } else {
      setValue("pix_key", pix_key || "phone");
      setValue("pix_value", pix_value || "");
    }
  };

  return (
    <>
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />
      <Modal
        closeTimeoutMS={500}
        isOpen={isOpen}
        onRequestClose={close}
        className="shadow side-modal"
      >
        <HeaderModal>
          <ButtonCloseArrow
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => close()}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </ButtonCloseArrow>
          <TitleModal>{t("configuration.bankAccount.title")}</TitleModal>
          <CloseButton className="close" onClick={() => close()}>
            <CloseIcon size={32} />
          </CloseButton>
        </HeaderModal>
        <ContainerFluid>
          <Row>
            <Col>
              {hasAccountBankPermission() && (
                <Fragment>
                  <Alert
                    variant="danger"
                    className="mt-3 w-100"
                    style={{ borderRadius: "8px" }}
                  >
                    <span className="icon-circle-sm">
                      <FontAwesomeIcon icon={faExclamation} />
                    </span>
                    <span>
                      <b>{t("configuration.bankAccount.alert.title")}</b>
                      <span>{t("configuration.bankAccount.alert.text")}</span>
                      <b>{organizationName}</b>
                    </span>
                  </Alert>
                </Fragment>
              )}
            </Col>
          </Row>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row style={{ borderBottom: "1px solid #d8e2e7" }}>
              <Col md="6">
                <FormField
                  label={t("configuration.bankAccount.label.holderName")}
                  errorMessage={errors.holder_name?.message}
                  controlId="validationholder_name"
                >
                  <Form.Control
                    {...register("holder_name")}
                    readOnly={!hasAccountBankPermission()}
                    placeholder={t(
                      "configuration.bankAccount.placeholder.holderName"
                    )}
                    name="holder_name"
                    type="text"
                    isInvalid={errors.holder_name?.message}
                  />
                </FormField>
              </Col>
              <Col md="6">
                <FormField
                  label={t("configuration.bankAccount.label.holderDocument")}
                  errorMessage={errors.holder_document?.message}
                  controlId="validationholder_document"
                >
                  <Form.Control
                    {...register("holder_document")}
                    readOnly={!hasAccountBankPermission()}
                    placeholder={t(
                      "configuration.bankAccount.placeholder.holderDocument"
                    )}
                    onChange={(e) =>
                      setValue("holder_document", cpfMask(e.target.value))
                    }
                    name="holder_document"
                    type="text"
                    isInvalid={errors.holder_document?.message}
                  />
                </FormField>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md="6">
                <FormField
                  label={t("configuration.bankAccount.label.transferBank")}
                  errorMessage={errors.transfer_bank_id?.message}
                  controlId="validationtransfer_bank_id"
                >
                  <Select
                    defaultValue={
                      watch("transfer_bank_id")
                        ? {
                            value: watch("transfer_bank_id"),
                            label: banks.find(
                              (bank) => bank.value === watch("transfer_bank_id")
                            ).name,
                          }
                        : null
                    }
                    options={banks.map((bank) => ({
                      value: bank.value,
                      label: bank.name,
                    }))}
                    isDisabled={!hasAccountBankPermission()}
                    name="transfer_bank_id"
                    onChange={selectBank}
                    placeholder={t(
                      "configuration.bankAccount.placeholder.transferBank"
                    )}
                  />
                  {errors.transfer_bank_id?.message && (
                    <ErrorFont className="text-danger mt-1">
                      {t("configuration.bankAccount.validation.required.bank")}
                    </ErrorFont>
                  )}
                </FormField>
              </Col>
              <Col md="6">
                <FormField
                  label={t("configuration.bankAccount.label.accountType")}
                  errorMessage={errors.account_type?.message}
                  controlId="validationaccount_type"
                >
                  <Form.Control
                    {...register("account_type")}
                    disabled={!hasAccountBankPermission()}
                    name="account_type"
                    as="select"
                    isInvalid={errors.account_type?.message}
                  >
                    <option value="currency">
                      {t("configuration.bankAccount.type.checking")}
                    </option>
                    <option value="savings">
                      {t("configuration.bankAccount.type.savings")}
                    </option>
                  </Form.Control>
                </FormField>
              </Col>
            </Form.Row>
            <Form.Row style={{ borderBottom: "1px solid #d8e2e7" }}>
              <Col md="6">
                <FormField
                  label={t("configuration.bankAccount.label.agency")}
                  errorMessage={errors.agency?.message}
                  controlId="validationagency"
                >
                  <Form.Control
                    {...register("agency")}
                    readOnly={!hasAccountBankPermission()}
                    name="agency"
                    maxLength="4"
                    onChange={(e) =>
                      setValue("agency", numberMask(e.target.value))
                    }
                    placeholder={t(
                      "configuration.bankAccount.placeholder.noDigit"
                    )}
                    type="text"
                    isInvalid={errors.agency?.message}
                  />
                </FormField>
              </Col>
              <Col md="3">
                <FormField
                  label={t("configuration.bankAccount.label.account")}
                  errorMessage={errors.account?.message}
                  controlId="validationaccount"
                >
                  <Form.Control
                    {...register("account")}
                    readOnly={!hasAccountBankPermission()}
                    name="account"
                    minLength="2"
                    maxLength="12"
                    onChange={(e) =>
                      setValue("account", numberMask(e.target.value))
                    }
                    placeholder={t(
                      "configuration.bankAccount.placeholder.noDigit"
                    )}
                    type="text"
                    isInvalid={errors.account?.message}
                  />
                </FormField>
              </Col>
              <Col md="3">
                <FormField
                  label={t("configuration.bankAccount.label.digit")}
                  errorMessage={errors.account_digit?.message}
                  controlId="validationaccount_digit"
                >
                  <Form.Control
                    {...register("account_digit")}
                    readOnly={!hasAccountBankPermission()}
                    name="account_digit"
                    maxLength="1"
                    onChange={(e) =>
                      setValue("account_digit", numberMask(e.target.value))
                    }
                    placeholder={t(
                      "configuration.bankAccount.placeholder.noDigit"
                    )}
                    type="text"
                    isInvalid={errors.account_digit?.message}
                  />
                </FormField>
              </Col>
            </Form.Row>
            <TermsContainer>
              <TermsCheckbox
                {...register("checked")}
                disabled={!hasAccountBankPermission()}
                type="checkbox"
                defaultChecked={checked}
                onClick={handleHasPix}
              />
              <TermsSubtitle className="form-check-label">
                {t("configuration.bankAccount.label.pixCheck")}
              </TermsSubtitle>
            </TermsContainer>
            {checked && (
              <Form.Row>
                <Col md="6">
                  <FormField
                    label={t("configuration.bankAccount.label.pixKeyType")}
                    errorMessage={errors.pix_key?.message}
                    controlId="validationpix_key"
                  >
                    <Form.Control
                      disabled={!hasAccountBankPermission()}
                      name="pix_key"
                      defaultValue={pix_key}
                      onChange={handleResetPixValue}
                      as="select"
                      isInvalid={errors.pix_key?.message}
                    >
                      <option value="" disabled>
                        {t("configuration.bankAccount.placeholder.pixKeyType")}
                      </option>
                      <option value="phone">
                        {t("configuration.bankAccount.options.phone")}
                      </option>
                      <option value="document">
                        {t("configuration.bankAccount.options.document")}
                      </option>
                      <option value="email">
                        {t("configuration.bankAccount.options.email")}
                      </option>
                      <option value="random">
                        {t("configuration.bankAccount.options.random")}
                      </option>
                    </Form.Control>
                  </FormField>
                </Col>
                <Col md="6">
                  <FormField
                    label={t("configuration.bankAccount.label.pixKey")}
                    errorMessage={errors.pix_value?.message}
                    controlId="validationpix_value"
                  >
                    {keyType === "document" && (
                      <Form.Control
                        {...register("pix_value")}
                        readOnly={!hasAccountBankPermission()}
                        name="pix_value"
                        isInvalid={errors.pix_value?.message}
                        onChange={(e) =>
                          setValue("pix_value", cpfMask(e.target.value))
                        }
                        placeholder={t(
                          "configuration.bankAccount.placeholder.pixKey"
                        )}
                        type="text"
                      />
                    )}
                    {keyType === "phone" && (
                      <Form.Control
                        {...register("pix_value")}
                        readOnly={!hasAccountBankPermission()}
                        name="pix_value"
                        onChange={(e) =>
                          setValue("pix_value", phoneMask(e.target.value))
                        }
                        isInvalid={errors.pix_value?.message}
                        placeholder={t(
                          "configuration.bankAccount.placeholder.pixKey"
                        )}
                        type="text"
                      />
                    )}
                    {keyType === "email" && (
                      <Form.Control
                        {...register("pix_value")}
                        readOnly={!hasAccountBankPermission()}
                        name="pix_value"
                        isInvalid={errors.pix_value?.message}
                        placeholder={t(
                          "configuration.bankAccount.placeholder.pixKey"
                        )}
                        type="text"
                      />
                    )}
                    {keyType === "random" && (
                      <Form.Control
                        {...register("pix_value")}
                        readOnly={!hasAccountBankPermission()}
                        maxLength={RANDOM_CHARACTER__MAX_LENGTH}
                        name="pix_value"
                        isInvalid={errors.pix_value?.message}
                        placeholder={t(
                          "configuration.bankAccount.placeholder.pixKey"
                        )}
                        type="text"
                      />
                    )}
                  </FormField>
                </Col>
              </Form.Row>
            )}
            <Col
              style={{
                display: "flex",
                padding: "0",
                justifyContent: "end",
                marginTop: "15px",
              }}
            >
              {hasAccountBankPermission() && (
                <Fragment>
                  <CustomButtonSpinner
                    type="submit"
                    isLoading={isLoading}
                    disabled={!isDirty}
                    customStyle="btn btn-secondary col-md-6 col-12"
                    text={
                      holder_name
                        ? t("configuration.bankAccount.button.update")
                        : t("configuration.bankAccount.button.add")
                    }
                  />
                </Fragment>
              )}
            </Col>
          </Form>
        </ContainerFluid>
      </Modal>
    </>
  );
}

const HeaderModal = styled.div`
  justify-content: space-between;
  padding: 9px;
  height: 48px;
  border-bottom: 1px #d8e2e7 solid;
  margin-top: 10px;
`;

const TitleModal = styled.span`
  padding-left: 10px;
  color: #98afbc;
  text-transform: uppercase;
  font-size: 14px;
  white-space: nowrap;
  font-family: Roboto, sans-serif;
  font-weight: 500;
`;

const ButtonCloseArrow = styled.button`
  padding-left: 10px;
  color: #98afbc;
  border: none;
  background-color: #fff;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
`;

const CloseIcon = styled(IoIosCloseCircleOutline)`
  color: ${COLORS.gray};
  font-size: 34px;
`;

const TermsContainer = styled.div`
  border: 2px solid #d8e2e7;
  border-radius: 8px;
  padding: 16px 0;
  background: #d8e2e7;
  margin-top: 10px;
`;

const TermsSubtitle = styled.span`
  color: #4f6c7c;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  font-family: "Roboto";
`;

const TermsCheckbox = styled.input`
  width: 19px;
  height: 14px;
  border: 2px solid rgb(79 108 124 / 59%);
  border-radius: 3px;
  margin: 5px 5px;
  cursor: pointer;
`;

const ErrorFont = styled.span`
  font-size: 13px;
`;
