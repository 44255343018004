import Check from "../../assets/icons/Check.svg";
import React from "react";
import Switch from "react-switch";
import "./styles.css";

export default function ToggleSwitch(props) {
  const { checked, onChange, onColor, offColor } = props;

  return (
    <div id="switch-button">
      <Switch
        {...props}
        onChange={onChange}
        checked={checked}
        onColor={onColor || "#00C67F"}
        uncheckedIcon
        offColor={offColor || "#B7B6BF"}
        handleDiameter={22}
        checkedIcon={
          <div className="switch-icon">
            <img src={Check} alt="check icon" />
          </div>
        }
      />
    </div>
  );
}
