import React from "react";
import {ReactComponent as InfoTool} from "../../assets/icons/infoTool.svg"

export const ToolTip = ({tooltip, classes, icon = null}) => (
	<div className={`tips ${classes}`}>
		{icon ? icon : <InfoTool width="1rem" height='1rem' />}
		<div className="tips-content">
		{tooltip}
		</div>
	</div>
)