import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

ModalTypeButton.propTypes = {
  title: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  modalType: PropTypes.string.isRequired,
  typeModalCourtesy: PropTypes.string.isRequired,
  children: PropTypes.element,
};

export default function ModalTypeButton({
  title,
  handleClick,
  modalType,
  typeModalCourtesy,
  children,
}) {
  return (
    <ModalIconButton
      onClick={handleClick}
      typeModalCourtesy={typeModalCourtesy}
      modalType={modalType}
			isActive={typeModalCourtesy === modalType}
    >
      {React.cloneElement(children, {
        stroke: typeModalCourtesy === modalType ? "#0050C3" : "#525059",
      })}
      {title}
    </ModalIconButton>
  );
}

const ModalIconButton = styled.button`
  width: 100%;
  border: 1px solid #eae9ed;
  border-radius: 0.5rem;
  :hover {
    border: ${({ isActive }) => !isActive && "1px solid #d3d2d9"};
  }
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-weight: bold;
  color: #525059;
  background-color: white;
  gap: 0.5rem;
  border: ${({ typeModalCourtesy, modalType }) =>
    typeModalCourtesy === modalType
      ? "1px solid #0050C3"
      : "1px solid #EAE9ED"};
  color: ${({ typeModalCourtesy, modalType }) =>
    typeModalCourtesy === modalType ? "#0050C3" : "#525059"};
  background-color: ${({ typeModalCourtesy, modalType }) =>
    typeModalCourtesy === modalType ? "#E6EEF9" : "white"};
`;
