import React from "react";
import { ReactComponent as Delete } from "../../../../assets/icons/delete.svg";
import { ReactComponent as Edit } from "../../../../assets/icons/edit.svg";

import { useTranslation } from "react-i18next";
import { formatCurrency } from "utils";
import { DropdownBtn } from "../components/dropdown";

export const useBorderoDeductions = ({
  currency,
  handleEdit,
  handleDelete,
}) => {
  const { t } = useTranslation("tickets");
  const deductionRowsCurrencyFormatter = {
    Antecipação: true,
    "Taxas de Pagamento": true,
  };

  const centsCurrencyFormatter = (value) =>
    formatCurrency(value, {
      isCents: true,
      currency: currency,
    });

  const deducationsHeaderTranslation = {
    costsTotal: t("financialStatement.costsTotal"),
    otherExpensesTotal: t("financialStatement.otherExpenses"),
    deductionsTotal: t("financialStatement.totalDeductions"),
  };
  const deductionsTooltips = {
    "Online para terceiros": t("financialStatement.onlineWithdrawTooltip"),
    "PDV para terceiros": t("financialStatement.POSWithdrawTooltip"),
    "Cortesia Online": t("financialStatement.onlineCourtesyTooltip"),
    "Crédito (POS)": t("financialStatement.posCreditTaxTooltip"),
    "Débito (POS)": t("financialStatement.posDebitTaxTooltip"),
    "PIX (POS)": t("financialStatement.posPixTaxTooltip"),
    "Impressão de Ingresso": t("financialStatement.ticketPrintTooltip"),
    "Impressão de Cortesia": t("financialStatement.courtesyPrintTooltip"),
    "Cancelamento de Ingresso Impresso": t(
      "financialStatement.canceledTicketPrintTooltip"
    ),
    "Antecipação (POS)": t("financialStatement.posAnticipationTooltip"),
    "Antecipação (Online)": t("financialStatement.onlineAnticipationTooltip"),
    "Ingresso online em dinheiro (comissários)": t(
      "financialStatement.onlineCashTooltip"
    ),
    costsTotal: t("financialStatement.costsTotalTooltip"),
    deductionsTotal: t("financialStatement.totalDeductionsTooltip"),
  };

  const deductionRowsTranslations = {
    "Online para terceiros": t("financialStatement.forThirdPartyOnline"),
    "PDV para terceiros": t("financialStatement.forThirdPartyPDV"),
    "Cortesia Online": t("financialStatement.onlineCourtesy"),
    "Crédito (POS)": t("financialStatement.posCredit"),
    "Débito (POS)": t("financialStatement.posDebit"),
    "PIX (POS)": t("financialStatement.posPix"),
    "Impressão de Ingresso": t("financialStatement.ticketPrinting"),
    "Impressão de Cortesia": t("financialStatement.courtesyPrinting"),
    "Cancelamento de Ingresso Impresso": t(
      "financialStatement.canceledTicketPrinting"
    ),
    "Antecipação (POS)": t("financialStatement.posAnticipation"),
    "Antecipação (Online)": t("financialStatement.onlineAnticipation"),
    "Ingresso online em dinheiro (comissários)": t(
      "financialStatement.onlineCashTicketSale"
    ),
    "Taxas de Pagamento": t("financialStatement.paymentTax"),
    "Emissão de Ingressos": t("financialStatement.ticketEmission"),
    Saque: t("financialStatement.withdraw"),
    Total: t("financialStatement.total"),
  };

  const deductionsHelpers = {
    mapHeader: (data) =>
      Object.entries(data).map(([key, value]) => {
        return {
          id: key,
          amount: centsCurrencyFormatter(value),
          description: deducationsHeaderTranslation[key],
        };
      }),

    mapOtherCosts: (apiData) => {
      const { costs, totals } = apiData;
      const rows = costs.reduce((acc, cost) => {
        acc.push({
          name: deductionRowsTranslations[cost.cost] || cost.cost,
          quantity: deductionRowsCurrencyFormatter[cost.cost]
            ? centsCurrencyFormatter(cost.qty)
            : cost.qty,
          tax: cost.tax,
          total: centsCurrencyFormatter(cost.total),
          isHeader: true,
          empty: "",
        });

        acc.push(
          ...cost.lines.map(({ name, qty, tax, total, parsedTax }) => {
            return {
              name: deductionRowsTranslations[name],
              quantity: deductionRowsCurrencyFormatter[cost.cost]
                ? centsCurrencyFormatter(qty)
                : qty,
              tax: parsedTax || tax,
              total: centsCurrencyFormatter(total),
              tooltip: deductionsTooltips[name],
              empty: "",
            };
          })
        );
        return acc;
      }, []);

      return {
        columns: [
          {
            title: t("financialStatement.otherCosts"),
            colSpan: 8,
            align: "left",
          },
          {
            title: t("financialStatement.quantityValue"),
            colSpan: 3,
            align: "right",
          },
          {
            title: t("financialStatement.taxOrCost"),
            colSpan: 3,
            align: "right",
          },
          {
            title: t("financialStatement.total"),
            colSpan: 3,
            align: "right",
          },
          {
            title: "",
            colSpan: 1,
            align: "center",
          },
        ],
        rows,
        total: {
          quantity: "-",
          tax: "-",
          total: centsCurrencyFormatter(totals.total),
          empty: "",
        },
      };
    },

    mapTables: (data) => {
      const otherExpensesRows = data.otherExpenses.expenses.map((expense) => {
        return {
          name: expense.name,
          quantity: expense.quantity,
          unitValue: centsCurrencyFormatter(expense.value),
          total: centsCurrencyFormatter(expense.totalValue),
          options: () => (
            <DropdownBtn
              options={[
                {
                  label: t("financialStatement.edit"),
                  action: () => handleEdit(expense),
                  leftIcon: <Edit />,
                },
                {
                  label: t("financialStatement.delete"),
                  action: () => handleDelete(expense.id),
                  leftIcon: <Delete />,
                },
              ]}
            />
          ),
        };
      });

      const otherExpensesTable = {
        columns: [
          {
            title: t("financialStatement.eventExpenses"),
            colSpan: 8,
            align: "left",
          },
          {
            title: t("financialStatement.quantityValue"),
            colSpan: 3,
            align: "right",
          },
          {
            title: t("financialStatement.unitValue"),
            colSpan: 3,
            align: "right",
          },
          {
            title: t("financialStatement.total"),
            colSpan: 3,
            align: "right",
          },
          {
            title: "",
            colSpan: 1,
            align: "center",
          },
        ],
        rows: otherExpensesRows,
        total: {
          totalQuantity: data.otherExpenses.total.totalQuantity,
          totalUnitValue: "-",
          totalValue: centsCurrencyFormatter(
            data.otherExpenses.total.totalValue
          ),
          btn: "",
        },
      };

      const otherCosts = deductionsHelpers.mapOtherCosts(data.costs);

      return {
        tables: [otherCosts, otherExpensesTable],
      };
    },
  };

  return {
    deductionsHelpers,
  };
};
