import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./styles.css";
import { useTranslation } from "react-i18next";

HeaderNavigationBar.propTypes = {
  navlinks: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      position: PropTypes.number,
    })
  ),
  selectedPosition: PropTypes.func,
};

export default function HeaderNavigationBar(props) {
  let { navlinks, selectedPosition } = props;
  const { t } = useTranslation("organizations", {
    keyPrefix: "configuration",
  });

  navlinks = navlinks.map((navlink) => {
    const { text, position, show = true } = navlink;

    const newNavlink = {
      text,
      position,
      active: false,
      show,
    };

    if (newNavlink.position === 1) {
      newNavlink.active = true;
    }

    return newNavlink;
  });

  navlinks = navlinks.filter((navlink) => navlink.show);

  const [active, setActive] = useState(navlinks);

  function changeState(value) {
    let changeNavlinks = active;
    if (!value) {
      value = 1;
    }
    const changedNavlink = changeNavlinks.map((navlink, index) => {
      if (navlink.position === value) {
        navlink.active = true;
        return navlink;
      }
      navlink.active = false;
      return navlink;
    });
    setActive(changedNavlink);
    selectedPosition(!value ? 0 : value);
  }

  useEffect(() => {
    changeState();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="col" id="subheader">
      <ul className="nav nav-tabs nav-overflow header-tabs">
      {active.map((item, index) => {
          return (
            <li className="nav-item" key={item.position}>
              <button
                className="nav-link text-nowrap subheader-item"
                style={
                  item.active
                    ? {
                        borderBottomColor: "#2c7be5",
                        color: "#12263f",
                      }
                    : null
                }
                onClick={() => changeState(item.position)}
              >
                {t(item.text)}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
