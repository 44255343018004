import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faPrint,
  faQrcode,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Alert, Button, Card, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastr";
import Swal from "sweetalert2";
import CustomButtonSpinner from "components/buttonSpinner";
import DataTip from "components/data-tip";
import Loading from "components/loading";
import API from "services/api";
import New_api from "services/new-api";
import Clean from "../../../../src/assets/icons/Clean.svg";
import dragVertical from "../../../../src/assets/icons/Drag-Vertical.svg";
import editPencil from "../../../../src/assets/icons/editPencilNew.svg";
import lockIcon from "../../../../src/assets/icons/Lock-Icon.svg";
import rIcon from "../../../../src/assets/icons/R-icon.svg";
import { ReactComponent as RecycleBin } from "../../../../src/assets/icons/trashCan.svg";
import Comprovante from "../participantes/comprovante/index";
import ModalVendas from "../vendas/modalVendas";
import CustomFieldsModal from "./customFieldsModal";
import ModalReset from "./modalReset";

import "./styles.css";
import { useTranslation } from "react-i18next";
import CustomTag from "components/customTag";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import printJS from "print-js";
import _ from "lodash";
import NewPageHeader from "components/newPageHeader";
import ButtonContained from "components/buttonContained";
import api from "services/api";

let porPagina = 25;
let container;

export function formataDataHora(data) {
  let date = new Date(data);
  return (
    ("00" + date.getDate()).slice(-2) +
    "/" +
    ("00" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear() +
    " " +
    ("00" + date.getHours()).slice(-2) +
    ":" +
    ("00" + date.getMinutes()).slice(-2) +
    ":" +
    ("00" + date.getSeconds()).slice(-2)
  );
}
export function Participantes() {
  const printTagRef = useRef();

  const urlParams = new URLSearchParams(window.location.search);
  const event_id = urlParams.get("event_id");
  const userInformations = JSON.parse(localStorage.getItem("user"));
  const [showModal, setShowModal] = useState("hidden");
  const [props /*setProps*/] = useState();
  const [, setDownload] = useState(false);
  const [changeItem, setChangeItem] = useState(0);
  const [atualizaPaginaAtual, setAtualizaPaginaAtual] = useState();
  const [atualizaUltimaPagina, setAtualizaUltimaPagina] = useState([]);
  const [atualizaQuantPesquisa, setAtualizaQuantPesquisa] = useState();
  const [formQuestions, setFormQuestions] = useState([]);
  const [question, setQuestion] = useState(null);
  const [participanteId, setParticipanteId] = useState(null);
  const [showModalVendas, setShowModalVendas] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [enableTag, setEnableTag] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const [sectors, setSectors] = useState([]);
  const [userInfo, setUserInfo] = useState({ data: { fields: [] }, code: "" });
  const [configTag, setConfigTag] = useState(null);
  const [pesquisa, setPesquisa] = useState({
    pesquisa: "",
  });
  const [ordBy, setOrdBy] = useState({
    Column: "data",
    ord: "asc",
  });
  const [tickets, setTickets] = useState([]);
  const { t } = useTranslation("participants");

  function rotate(element, value) {
    let rotated = document.getElementsByClassName("rotated");
    let active = document.getElementsByClassName("arrow-active");
    for (let index = 0; index < rotated.length; index++) {
      rotated[index].classList.remove("rotated");
    }
    for (let index = 0; index < active.length; index++) {
      active[index].classList.remove("arrow-active");
    }
    element.target.classList.add("arrow-active");
    if (ordBy.ord === "asc" && ordBy.Column === value) {
      element.target.classList.add("rotated");
      setOrdBy({ Column: value, ord: "desc" });
    } else {
      element.target.classList.remove("rotated");
      setOrdBy({ Column: value, ord: "asc" });
    }
  }

  useEffect(() => {
    detailsTickets("initial", 1, porPagina);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordBy, enableTag]);

  useEffect(() => {
    setIsLoading(true);
    if (changeItem === 1) {
      detailsForms();
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeItem, isReload]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      detailsTickets("initial", 1, porPagina, pesquisa.pesquisa);
    }
  };

  const handleOnDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const originalQuestions = Array.from(formQuestions);
    const questions = Array.from(formQuestions);

    const [reorderedItem] = questions.splice(result.source.index, 1);
    questions.splice(result.destination.index, 0, reorderedItem);

    const questionsWithNewIndex = questions.map((question, index) => {
      return { ...question, order_field: index };
    });

    for (let i = 0; i < questionsWithNewIndex.length; i++) {
      let questionItem = document.getElementsByClassName("question");
      for (let i = 0; i < questionItem.length; i++) {
        questionItem[i].classList.remove("dragging");
      }
    }
    setFormQuestions(questionsWithNewIndex);

    const reorderedQuestions = questionsWithNewIndex.map((question) => ({
      id: question.id,
      order_field: question.order_field,
    }));

    await New_api.post(`event-forms/new-order?event_id=${event_id}`, {
      form: reorderedQuestions,
    })
      .then(() => {
        container.success("Os campos personalizados foram ordenados.");
      })
      .catch(() => {
        setFormQuestions(originalQuestions);
        container.error("Ocorreu um erro ao tentar ordernar os campos");
      });
  };

  const handleOnDragStart = (question) => {
    let questionItem = document.getElementsByClassName("question");
    for (let i = 0; i < questionItem.length; i++) {
      questionItem[i].classList.remove("dragging");
    }
    questionItem[question.source.index].classList.add("dragging");
  };

  const updateData = (e) => {
    setPesquisa({
      [e.target.name]: e.target.value,
    });
  };

  const [usuarioLogado /*setUsuarioLogado*/] = useState(
    JSON.parse(localStorage.getItem("user"))
  );

  function openModalParticipantes(id) {
    setParticipanteId(id);
    setShowModalVendas(true);
  }

  function closeComprovante() {
    setShowModal("hidden");
  }

  const realizarCheckin = (codigo) => {
    API.post("checkin/event", {
      event_id: event_id,
      code: codigo,
      array: "Todos",
    })
      .then(() => {
        container.success("Check-in realizado!");
        detailsTickets();
      })
      .catch((err) => {
        container.error(err.response.data.message);
      });
  };

  const detailsTickets = async (typePage, numberPage, perPage, search) => {
    setIsLoading(true);
    let pageConsult = {
      page: numberPage,
      perPage: perPage,
      search: search,
      ordBy: ordBy,
    };
    switch (typePage) {
      case "Initial":
        pageConsult = {
          page: 1,
          perPage: perPage,
          search: pesquisa.pesquisa,
          ordBy: ordBy,
        };
        break;
      case "right":
        if (numberPage >= atualizaUltimaPagina) {
          pageConsult = {
            page: numberPage,
            perPage: perPage,
            search: pesquisa.pesquisa,
            ordBy: ordBy,
          };
        } else {
          numberPage++;
          pageConsult = {
            page: numberPage,
            perPage: perPage,
            search: pesquisa.pesquisa,
            ordBy: ordBy,
          };
        }
        break;
      case "left":
        numberPage--;
        pageConsult = {
          page: numberPage,
          perPage: perPage,
          search: pesquisa.pesquisa,
          ordBy: ordBy,
        };
        if (numberPage <= 0) {
          pageConsult = {
            page: 1,
            perPage: perPage,
            search: pesquisa.pesquisa,
            ordBy: ordBy,
          };
        }
        break;
      case "lastPage":
        pageConsult = {
          page: numberPage,
          perPage: perPage,
          search: pesquisa.pesquisa,
          ordBy: ordBy,
        };
        break;
      default:
        pageConsult = {
          page: 1,
          perPage: perPage,
          search: pesquisa.pesquisa,
          ordBy: ordBy,
        };
    }

    await New_api.get(`/events/${event_id}/event-tag-settings`)
      .then((res) => {
        const { data } = res;

        setConfigTag(data);
      })
      .catch(() => {
        return null;
      });

    API.post(`tickets/event/${event_id}`, pageConsult)
      .then((res) => {
        setTickets(res.data.detailsTickets.data);
        setAtualizaPaginaAtual(res.data.detailsTickets.page);
        setAtualizaUltimaPagina(res.data.detailsTickets.lastPage);
        setAtualizaQuantPesquisa(res.data.detailsTickets.total);
        setSectors(res.data.sectors);
        setDownload(true);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.data.error.status === 500) {
          container.error("Rota não encontrada");
        } else {
          container.error(err.response.data.error.message);
        }
        setIsLoading(false);
      });
  };

  const detailsForms = async () => {
    const formQuestions = await New_api.get(`/event-forms/${event_id}`);
    setFormQuestions(formQuestions.data.data);
  };

  const editModal = async (id) => {
    setIsLoading(true);
    const question = await New_api.get(`/event-forms/details/${id}`);

    const questionInfos = {
      id: question.data?.id,
      description: question.data?.description,
      field_list: question.data?.field_list,
      required: !!question.data?.required,
      instructions: question.data?.instructions,
      order_field: question.data?.order_field,
      field: {
        label: question.data?.fieldType?.description,
        value: question.data?.fieldType?.id,
        type: question.data?.fieldType?.type,
      },
    };

    setQuestion(questionInfos);
    setIsLoading(false);
    setIsOpen(true);
  };

  const disableQuestionForm = (id, description) => {
    Swal.fire({
      title: `Deseja excluir o campo "${description}" ?`,
      icon: "warning",
      text: "Essa ação não pode ser desfeita, mas você pode criar o campo novamente repetindo as etapas.",
      showCancelButton: true,
      confirmButtonText: `Excluir campo <i class="far fa-trash-alt"></i>`,
      cancelButtonText: "Voltar",
      reverseButtons: true,
      buttonsStyling: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        await New_api.delete(`/event-forms/${id}`)
          .then(() => {
            setIsReload(true);
            setIsLoading(false);
            container.success("O campo personalizado foi excluído");
            setIsReload(false);
          })
          .catch(() => {
            setIsReload(true);
            setIsLoading(false);
            container.error("Esse campo personalizado não pode ser excluído");
            setIsReload(false);
          });
      }
    });
  };

  const validaRealizarCheckin = (code) => {
    Swal.fire({
      title: "Você tem certeza que deseja Realizar o Check-in?",
      text: "Você não poderá recuperar ação depois de processada!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, Realizar Check-in!",
      cancelButtonText: "Não",
    }).then((result) => {
      if (result.value) {
        realizarCheckin(code);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const getTagInformations = async (ticket) => {
    const { ticketId, code } = ticket;

    const { data } = await New_api.get(`tickets/${ticketId}`, {
      params: { custom_tag: true },
    });

    setUserInfo({ data, code });
  };

  const handlePrint = async () => {
    const width = configTag.width / 100;
    const height = configTag.height / 100;

    const input = printTagRef.current;
    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "cm",
      format: [height, width],
    });
    pdf.addImage(imgData, "PNG", 0, 0, width, height);
    const pdfBlob = pdf.output("blob");

    const reader = new FileReader();
    reader.onload = function () {
      const dataUrl = reader.result;
      const base64 = dataUrl.split(",")[1];
      printJS({ printable: base64, type: "pdf", base64: true });
    };
    reader.readAsDataURL(pdfBlob);
  };

  function handleVendasUpdates() {
    setShowModalVendas(false);
    setParticipanteId(null);

    detailsTickets("lastPage", atualizaPaginaAtual, porPagina);
  }

  const handleClose = () => {
    setIsOpen(false);
    setQuestion(null);
  };

  const generatorXLSX = () => {
    api
      .post(
        `/generatorPartXLSX/${"xlsx"}`,
        {
          event: event_id,
          search: pesquisa.pesquisa,
        },
        { responseType: "arraybuffer" }
      )
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${"Participantes"}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  };

  return (
    <>
      {isLoading && (
        <Fragment>
          <Loading
            title="aguarde..."
            text="estamos carregando a lista de participantes :)"
          />
        </Fragment>
      )}

      <NewPageHeader
        title="Participantes"
        subtitle="Listagem detalhada de participantes inseridos nas compras realizadas."
        className="bg-white"
      >
        <ButtonContained
          onClick={() => generatorXLSX()}
          className="primary-contained new-primary-contained"
          content={"Exportar Lista"}
        />
      </NewPageHeader>
      <div className="col" id="subheader">
        <ul className="nav nav-tabs nav-overflow header-tabs">
          <li className="nav-question">
            <span
              className={`nav-link text-nowrap subheader-item ${
                changeItem === 0 && "active"
              }`}
              onClick={() => setChangeItem(0)}
            >
              Lista de participantes
            </span>
          </li>
          <li className="nav-question">
            <span
              className={`nav-link text-nowrap subheader-item ${
                changeItem === 1 && "active"
              }`}
              onClick={() => setChangeItem(1)}
            >
              Formulário de Inscrição
            </span>
          </li>
          <li className="nav-question">
            <span
              className={`nav-link text-nowrap subheader-item ${
                changeItem === 2 && "active"
              }`}
              onClick={() => setChangeItem(2)}
            >
              {t("participants:tag.mainTitle")}
            </span>
          </li>
        </ul>
      </div>

      {changeItem === 0 ? (
        <Fragment>
          <div
            id="page-participants"
            className="pagetype-tickets container-fluid mt-4"
            style={{ paddingBottom: "60px" }}
          >
            <ToastContainer
              ref={(ref) => (container = ref)}
              className="toast-top-right"
            />
            <div className="col-12">
              <div className="row ">
                <div
                  className={`${
                    userInformations.is_admin ? "col-md-3" : "col-md-4"
                  } mb-2`}
                >
                  <NavLink
                    to={`/tickets/participantes/checkin?event_id=${event_id}`}
                  >
                    <button className="btn btn-secondary btn-height">
                      <FontAwesomeIcon icon={faQrcode} /> Validação via Qrcode
                    </button>
                  </NavLink>
                </div>
                {userInformations.is_admin ? (
                  <div className="col-md-3 mb-2">
                    <button
                      className="btn btn-outline-secondary btn-height"
                      onClick={() => setResetModal(true)}
                    >
                      Resetar checkins
                    </button>
                  </div>
                ) : null}
                <div
                  className={`${
                    userInformations.is_admin ? "col-md-4" : "col-md-6"
                  } mb-2`}
                >
                  <div className="card-search">
                    <FontAwesomeIcon icon={faSearch} className="icon-search" />
                    <input
                      type="text"
                      className="input-custom"
                      name="pesquisa"
                      placeholder="Pesquise pelo código ou nome"
                      onChange={updateData}
                      autoComplete="off"
                      value={pesquisa.pesquisa || ""}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                </div>
                <div className="col-md-2 mb-2">
                  <CustomButtonSpinner
                    customStyle="btn btn-outline-secondary btn-height"
                    value={pesquisa.pesquisa || ""}
                    triggerOnClick={detailsTickets}
                    isLoading={isLoading}
                    text="Pesquisar"
                  />
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="card">
                <div className="table-responsive">
                  <table
                    id="table-totalsales"
                    className="table table-sm card-table tb-small"
                  >
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Cod. Ingresso</th>
                        <th
                          scope="col"
                          className="text-center arrow-active"
                          onClick={(e) => rotate(e, "nome")}
                        >
                          Nome
                          <FontAwesomeIcon icon={faAngleDown} />
                        </th>
                        <th
                          scope="col"
                          className="text-center"
                          onClick={(e) => rotate(e, "ingresso")}
                        >
                          Tipo de Ingresso
                          <FontAwesomeIcon icon={faAngleDown} />
                        </th>
                        <th
                          scope="col"
                          className="text-center"
                          onClick={(e) => rotate(e, "checkin")}
                        >
                          Checkin
                          <FontAwesomeIcon icon={faAngleDown} />
                        </th>
                        <th
                          scope="col"
                          className="text-center"
                          onClick={(e) => rotate(e, "lote")}
                        >
                          Lote
                          <FontAwesomeIcon icon={faAngleDown} />
                        </th>
                        <th
                          scope="col"
                          className="text-center"
                          onClick={(e) => rotate(e, "setor")}
                        >
                          Setor
                          <FontAwesomeIcon icon={faAngleDown} />
                        </th>
                        {configTag && (
                          <th scope="col" className="text-center">
                            Etiqueta
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {tickets.map((ticket, i) => (
                        <tr
                          key={i}
                          style={{ cursor: "pointer" }}
                          onClick={() => openModalParticipantes(ticket.id)}
                        >
                          <td className="details-code-participantes">
                            {ticket.code}
                          </td>
                          <td className="text-center">
                            {_.compact([
                              ticket.partFname,
                              ticket.partLname,
                            ]).join(" ") || "Não informado"}
                          </td>
                          <td className="text-center">
                            {ticket.nameLot +
                              " " +
                              ticket.nameSector +
                              " " +
                              ticket.nameType}
                          </td>
                          <td className="text-center">
                            {ticket.isConsumed ? (
                              <>
                                {moment(ticket.data, "YYYY-MM-DD").format(
                                  "DD/MM/YYYY"
                                ) +
                                  " - " +
                                  ticket.hora}
                              </>
                            ) : (
                              <button
                                className="btn btn-primary btn-checkin"
                                disabled={
                                  moment(new Date()).format("DD/MM/YYYY") <
                                  moment(ticket.start_date).format("DD/MM/YYYY")
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  validaRealizarCheckin(ticket.code);
                                }}
                              >
                                {moment(new Date()).format("DD/MM/YYYY") <
                                moment(ticket.start_date).format(
                                  "DD/MM/YYYY"
                                ) ? (
                                  <>
                                    <FontAwesomeIcon icon={faTimesCircle} />
                                    <span>Check-in indisponível</span>
                                  </>
                                ) : (
                                  <>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                    <span>Realizar Check-in</span>
                                  </>
                                )}
                              </button>
                            )}
                          </td>
                          <td className="text-center">{ticket.nameLot}</td>
                          <td className="text-center">{ticket.nameSector}</td>
                          {configTag && (
                            <td
                              onClick={async (e) => {
                                e.stopPropagation();
                                await getTagInformations(ticket);
                                handlePrint();
                              }}
                              className="text-center"
                            >
                              <button className="print-tag-button">
                                <FontAwesomeIcon
                                  id="print-tag-icon"
                                  icon={faPrint}
                                />
                              </button>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="col-12">
                  <div className="row d-flex justify-content-between mb-3 datatable-footer">
                    <div className="col-md-6 d-flex align-items-center">
                      <span className="mr-2 ">Resultados por Página:</span>
                      <div>
                        <select
                          name="SelecaoPorPagina"
                          onChange={(e) =>
                            detailsTickets(
                              "initial",
                              1,
                              (porPagina = e.target.value)
                            )
                          }
                        >
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="75">75</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end align-items-center datatable-buttons">
                      {porPagina * atualizaPaginaAtual >=
                      atualizaQuantPesquisa ? (
                        <span>
                          {" "}
                          Exibindo {atualizaQuantPesquisa} de{" "}
                          {atualizaQuantPesquisa}{" "}
                        </span>
                      ) : (
                        <span>
                          {" "}
                          Exibindo {porPagina * atualizaPaginaAtual} de{" "}
                          {atualizaQuantPesquisa}{" "}
                        </span>
                      )}
                      <button
                        onClick={() => detailsTickets("initial", 1, porPagina)}
                      >
                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                      </button>
                      <button
                        onClick={() =>
                          detailsTickets("left", atualizaPaginaAtual, porPagina)
                        }
                      >
                        <FontAwesomeIcon icon={faAngleLeft} />
                      </button>
                      <span>
                        Página {atualizaPaginaAtual} de {atualizaUltimaPagina}
                      </span>
                      <button
                        onClick={() =>
                          detailsTickets(
                            "right",
                            atualizaPaginaAtual,
                            porPagina
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faAngleRight} />
                      </button>
                      <button
                        onClick={() =>
                          detailsTickets(
                            "lastPage",
                            atualizaUltimaPagina,
                            porPagina
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ position: "absolute", top: "-99999px" }}
            className="d-flex justify-content-center align-items-center"
          >
            <div
              ref={printTagRef}
              className="tag-preview preview-without-shadow"
              style={{ backgroundColor: "#ffffff" }}
            >
              <div
                className={`d-flex ${
                  configTag?.show_qrcode
                    ? "flex-row justify-content-between"
                    : "flex-column"
                } align-items-center m-3`}
                style={{ backgroundColor: "#ffffff" }}
              >
                <div className="tag-fields-items d-flex flex-column justify-content-center align-items-center">
                  {(userInfo?.data?.fields || []).map(
                    ({ field, value }, index) => (
                      <p
                        style={{
                          fontSize:
                            configTag.event_tag_fields.length === 1
                              ? "24px"
                              : "18px",
                        }}
                        key={index}
                      >
                        {field === "attendee_name" ? (
                          <>{value || "Participante não informado"}</>
                        ) : (
                          <>
                            {!!value
                              ? typeof value === "string"
                                ? value
                                : value.join(",")
                              : ""}
                          </>
                        )}
                      </p>
                    )
                  )}
                </div>
                {!!configTag?.show_qrcode && (
                  <div className="d-flex align-items-center">
                    <QRCode size={80} value={userInfo.code} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Fragment>
      ) : changeItem === 1 ? (
        <Fragment>
          <ToastContainer
            ref={(ref) => (container = ref)}
            className="toast-bottom-right"
          />
          <Card className="m-4">
            <Card.Body className="row">
              <Col md={6} sm={12}>
                <Card.Title>Campos do formulário de inscrição</Card.Title>
                <Card.Text>
                  Esses são os campos que participantes preenchem antes de
                  comprar seus ingressos.
                </Card.Text>
                <Button
                  className="col-md-6 col-sm-12"
                  variant="primary"
                  onClick={() => setIsOpen(true)}
                >
                  Adicionar campo personalizado
                  <img src={rIcon} alt="rIcon" className="pl-1" />
                </Button>

                <Alert className="card-alert d-flex flex-column align-items-start mt-5">
                  <Alert.Heading className="card-alert-title">
                    <img src={Clean} alt="Clean Icon" width={24} height={24} />{" "}
                    Zig Recomenda
                  </Alert.Heading>
                  <p className="card-alert-text ml-4">
                    Para taxas de conversão maiores e uma experiência de compra
                    mais rápida, utilize apenas as perguntas essenciais para a
                    compra dos ingressos do seu evento.
                  </p>
                </Alert>
              </Col>

              <Col md={6} sm={12}>
                <Card>
                  <Card.Body>
                    <Card.Title>Informações do participante</Card.Title>

                    <div className="row mt-2">
                      <div className="col-md-6">
                        <label htmlFor="user.first_name">
                          Nome <img src={lockIcon} alt="LockIcon" />{" "}
                        </label>
                        <input
                          type="text"
                          placeholder="Nome do participante"
                          className="form-control"
                          name="first_name"
                          disabled
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="last_name">
                          Sobrenome <img src={lockIcon} alt="LockIcon" />
                        </label>
                        <input
                          type="text"
                          placeholder="Sobrenome do participante"
                          className="form-control"
                          name="last_name"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mt-1">
                        <label htmlFor="email">
                          E-mail <img src={lockIcon} alt="LockIcon" />
                        </label>
                        <input
                          type="email"
                          placeholder="E-mail do participante"
                          className="form-control"
                          name="email"
                          disabled
                        />
                      </div>
                    </div>

                    <DragDropContext
                      onDragEnd={handleOnDragEnd}
                      onDragStart={handleOnDragStart}
                    >
                      <Droppable droppableId="questions">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {formQuestions.map((question, index) => (
                              <Draggable
                                key={question.id}
                                draggableId={`${question.id}`}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    className="row question"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div className="col-md-12 mt-3">
                                      <Card>
                                        <Card.Body>
                                          <div className="row align-items-center">
                                            <div className="col-md-1 d-flex align-items-center justify-content-center icon-drag-container">
                                              <img
                                                className="drag-vertical"
                                                src={dragVertical}
                                                alt="dragVertical Icon"
                                                width={24}
                                                height={24}
                                              />
                                            </div>
                                            <div className="col-md-9 mt-1">
                                              <label
                                                htmlFor={question.description}
                                              >
                                                {question.description}
                                                {question.required === 1 &&
                                                  "* (obrigatório)"}
                                                {question.instructions !==
                                                  null && (
                                                  <DataTip
                                                    tipContent={
                                                      question.instructions
                                                    }
                                                  />
                                                )}
                                              </label>
                                              <input
                                                placeholder="Selecione o motivo"
                                                type="text"
                                                className="form-control"
                                                name={question.description}
                                                disabled
                                              />
                                            </div>
                                            <div className="col-md-2 d-flex align-items-center h-50 justify-content-end icon-drag-container">
                                              <button
                                                className="icon-button"
                                                onClick={() =>
                                                  editModal(question.id)
                                                }
                                              >
                                                <img
                                                  src={editPencil}
                                                  alt="editPencil Icon"
                                                  width={24}
                                                  height={24}
                                                />
                                              </button>
                                              <button
                                                className="icon-button ml-1"
                                                onClick={() =>
                                                  disableQuestionForm(
                                                    question.id,
                                                    question.description
                                                  )
                                                }
                                              >
                                                <RecycleBin stroke="#f53c5a" />
                                              </button>
                                            </div>
                                          </div>
                                        </Card.Body>
                                      </Card>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Card.Body>
                </Card>
              </Col>
            </Card.Body>
          </Card>
        </Fragment>
      ) : (
        changeItem === 2 && (
          <CustomTag event_id={event_id} setEnableTag={setEnableTag} />
        )
      )}

      <Comprovante
        callback={closeComprovante}
        status={showModal}
        data={props}
      />

      <ModalVendas
        show={showModalVendas}
        onClose={() => setShowModalVendas(false)}
        compradorId={participanteId}
        eventId={event_id}
        usuarioLogado={usuarioLogado}
        onRefund={handleVendasUpdates}
      />

      <CustomFieldsModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        item={changeItem}
        setIsReload={setIsReload}
        onClose={handleClose}
        question={question}
        toast={container}
        event_id={+event_id}
        order_field={formQuestions.length}
      />

      <ModalReset
        isOpen={resetModal}
        sectors={sectors}
        event_id={+event_id}
        toast={container}
        callBack={detailsTickets}
        setIsOpen={setResetModal}
      />
    </>
  );
}
